import { Component, OnInit } from '@angular/core';
import { FormBuilder,FormGroup,FormControl,Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { NgxSpinnerService } from "ngx-spinner";


@Component({
  selector: 'app-myaccount',
  templateUrl: './myaccount.component.html',
  styleUrls: ['./myaccount.component.scss']
})
export class MyaccountComponent implements OnInit {

  public serverUrl = "http://localhost/projects/angular/westarsapi/api/";

  isLoggedOut: string= localStorage.getItem('isLoggedOut') ? localStorage.getItem('isLoggedOut'):'true';
  isLoggedIn:string=localStorage.getItem('isLoggedIn') ? localStorage.getItem('isLoggedIn'):'false';


  private token=localStorage.getItem('token');

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Basic '+this.token
    })
  };

  constructor(private fb: FormBuilder,private http:HttpClient,private router:Router, private SpinnerService: NgxSpinnerService) { }

  ngOnInit(): void {

    //alert(this.isLoggedOut+" "+this.isLoggedIn);
  }



  userLogin = new FormGroup({
    user_email: new FormControl('sudhagarx64@gmail.com',Validators.email),
    user_password: new FormControl('stars9842',Validators.required),
  });

  userUpdate = new FormGroup({

    //user_email: new FormControl('sudhagarx64@gmail.com',Validators.email),

    user_password: new FormControl('',Validators.required),
    user_new_password: new FormControl('',Validators.required)

  });

  userPwdReset = new FormGroup({
    user_email: new FormControl('',Validators.email)

    //password/reset
  });

  userRegister = new FormGroup({
    user_name: new FormControl('',Validators.required),
    user_email: new FormControl('',Validators.email),
    user_password: new FormControl('',Validators.required),
    user_conform_password: new FormControl('',Validators.required),
  });

  userRegisterSubmit()
  {

    alert('hi reg');

    //console.warn(this.userRegister.status);
    console.warn(this.userRegister.value);

    this.httpOptions.headers =this.httpOptions.headers.set('Authorization', 'my-new-auth-token');
    const res='';
    /* const res=this.http.post(serverUrl+'register', this.userRegister.value, this.httpOptions).subscribe(dt =>
    {

      console.log(dt);
      localStorage.setItem('user', JSON.stringify(dt));
    }); */


  }

  userLoginSubmit()
  {
    this.SpinnerService.show();
    const login_req=this.http.post(this.serverUrl+'login', this.userLogin.value, this.httpOptions);

    localStorage.setItem('token', '');
    localStorage.setItem('name', '');
    localStorage.setItem('email', '');

    login_req.subscribe(
      data  =>
      {
        //console.log("post Request is successful ", data);

        /* if(data.status=='success')
        {
          this.isLoggedOut = 'false';
          this.isLoggedIn = 'true';

          localStorage.setItem('isLoggedOut', (this.isLoggedOut).toString());
          localStorage.setItem('isLoggedIn', (this.isLoggedIn).toString());
          localStorage.setItem('token', data.data.token);

          localStorage.setItem('id', data.data.user_data.id);
          localStorage.setItem('name', data.data.user_data.name);
          localStorage.setItem('email', data.data.user_data.email);

          this.router.navigateByUrl('/myaccount');
          this.SpinnerService.hide();
          //alert('Hi '+data.data.user_data.name+' '+data.msg);

        }
        else
        {
          localStorage.setItem('token', '');
          localStorage.setItem('name', '');
          localStorage.setItem('email', '');
        } */

      },
      error  =>
      {
        /* this.isLoggedOut = 'true';
        this.isLoggedIn = 'false'; */

        console.log("Error", error);

        localStorage.setItem('isLoggedOut', (this.isLoggedOut).toString());
        localStorage.setItem('isLoggedIn', (this.isLoggedIn).toString());

        localStorage.setItem('token', '');
        localStorage.setItem('name', '');
        localStorage.setItem('email', '');


      });

    /* this.http.post(this.serverUrl+'login', this.userLogin.value, this.httpOptions)
    .pipe(map(user => {
      alert('hi log');
      //alert(user);
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(user));
        //this.userSubject.next(user);
             return user;
    })); */


  }

  userUpdateSubmit()
  {
    this.SpinnerService.show();
    const login_req=this.http.post(this.serverUrl+'login', this.userLogin.value, this.httpOptions);

    login_req.subscribe(
      data  =>
      {
        //console.log("post Request is successful ", data);

        /* if(data.status=='success')
        {

          this.isLoggedOut = false;
          this.isLoggedIn = true;

          localStorage.setItem('isLoggedOut', (this.isLoggedOut).toString());
          localStorage.setItem('isLoggedIn', (this.isLoggedIn).toString());
          localStorage.setItem('token', data.data.token);

          localStorage.setItem('name', data.data.user_data.name);
          localStorage.setItem('email', data.data.user_data.email);

          this.router.navigateByUrl('/home');
          this.SpinnerService.hide();
          alert('Hi '+data.data.user_data.name+' '+data.msg);


        }
        else
        {
          localStorage.setItem('token', '');
          localStorage.setItem('name', '');
          localStorage.setItem('email', '');
        }  */

      },
      error  =>
      {
        this.isLoggedOut = 'true';
        this.isLoggedIn = 'false';

        console.log("Error", error);

        localStorage.setItem('isLoggedOut', (this.isLoggedOut).toString());
        localStorage.setItem('isLoggedIn', (this.isLoggedIn).toString());

        localStorage.setItem('token', '');
        localStorage.setItem('name', '');
        localStorage.setItem('email', '');

      });

    /* this.http.post(this.serverUrl+'login', this.userLogin.value, this.httpOptions)
    .pipe(map(user => {
      alert('hi log');
      //alert(user);
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(user));
        //this.userSubject.next(user);
             return user;
    })); */


  }

  userPwdResetSubmit() {

    //console.warn(this.userRegister.status);
    console.warn(this.userPwdReset.value);


    this.http.get<any>(this.serverUrl+'csrf-cookie').subscribe(() => {

      this.http.post<any>(this.serverUrl+'password/reset', this.userPwdReset.value, this.httpOptions)
      .subscribe(success => console.log(success), error => console.log(error))

    })

    //this.httpOptions.headers =this.httpOptions.headers.set('Authorization', 'my-new-auth-token');

    /* const res=this.http.post(serverUrl+'pwdreset', this.userLogin.value, this.httpOptions).subscribe(dt =>
    {
      console.log(dt);
      localStorage.setItem('user', JSON.stringify(dt));
    }); */


  }



}
