import { Component, OnInit, HostListener, Inject } from '@angular/core';
/* import { trigger, state, transition, style, animate } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import { faBars,faPhone,faEnvelope,faUser,faSearch } from '@fortawesome/free-solid-svg-icons'; */

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})

export class NavigationComponent implements OnInit {

 // constructor(@Inject(DOCUMENT) document) { }

  ngOnInit(): void {
  }

  /* fabars=faBars;
  faphone=faPhone;
  faenvelope=faEnvelope;
  fafacebook=faUser;
  fasearch=faSearch;

  navbarCollapsed:boolean=true;
  searchCollapsed:boolean=true;


  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
     if (window.pageYOffset > 550) {
       let element = document.getElementById('main-menu');
       element.classList.add('fixed-header');
     } else {
      let element = document.getElementById('main-menu');
        element.classList.remove('fixed-header');
     }
  } */


}
