<footer class="footer mt-auto py-3 bg-light">
  <div class="container">
    <div class="copyright text-center">
      <p>இணைய தள காப்புரிமை {{ copyright_text }} <a [routerLink]="['/']">westars.in</a> அனைத்து உரிமைகளும் பாதுகாக்கப்பட்டவை</p>
   </div>
  </div>
  <button class="col-12" (click)="scrollToTop()" id="scroll-top" title="Scroll to Top" style="display: block;">
    <fa-icon [icon]="fachevronup"></fa-icon><span class="screen-reader-text">Scroll to Top</span>
  </button>
</footer>


