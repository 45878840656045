
<main role="main" id="maincontent">

  <div class="container-fluid no-gutters mt-4">
    <div class="container">

      <div class="row features mt-4 p-3">
        <div class="col-md-7 col-12 features_text">
          <h1 class="text-westars-head font-weight-bold">படைத்தல் கலையை விரும்புகிறோம்.</h1>

          வாடிக்கையாளர்களின் யோசனைகளைக் கேட்பது, தீர்வுகள் மூலம் சிந்திப்பது மற்றும் ஆக்கபூர்வமான
          வடிவமைப்புகளுடன் வருவது எங்கள் ஆர்வம். மிக முக்கியமாக, நாங்கள் அதை எளிமையாக வைத்திருக்கிறோம்.
        </div>
        <div class="col-md-5 col-12 more_details_div">
          <a [routerLink]="['/aboutus']" class="btn btn-outline-info more_details m-4 p-3">
            <span>
              எங்கள் முறைகளை பற்றி மேலும் அறிய </span>
          </a>
        </div>
      </div>

      <div class="row features_icons text-center text-westars-sub mt-4 p-3">

        <div class="col-6 col-md-3 features_icons_text">

          <div class="features_icons_container">
            <div class="features_icons_icon display-4">
              <fa-icon [icon]="faheart" class="fa_feature_icons"></fa-icon>
            </div>

            <div class="textwidget">
              <h5>
                கவனம் </h5>

            </div>

          </div>

        </div>

        <div class="col-6 col-md-3 features_icons_text">
          <div class="features_icons_container" >

            <div class="features_icons_icon display-4">
              <fa-icon [icon]="falightbulb" class="fa_feature_icons"></fa-icon>
            </div>

            <div class="textwidget">
              <h5>
                சிந்திப்பு </h5>


            </div>

          </div>
        </div>

        <div class="col-6 col-md-3 features_icons_text">

          <div class="features_icons_container">

            <div class="features_icons_icon display-4">
              <fa-icon [icon]="fapalette" class="fa_feature_icons"></fa-icon>
            </div>

            <div class="textwidget">
              <h5>
                வடிவமைப்பு </h5>
            </div>

          </div>

        </div>

        <div class="col-6 col-md-3 features_icons_text">
          <div class="features_icons_container" >

            <div class="features_icons_icon display-4">
              <fa-icon [icon]="fasyncalt" class="fa_feature_icons"></fa-icon>
            </div>

            <div class="textwidget">
              <h5>
                செதுக்கல் </h5>


            </div>

          </div>
        </div>

      </div>

    </div>
  </div>

  <div class="container-fluid no-gutters mt-4 bg-westars-light">
    <div class="container">

      <div class="row features_icons mt-4">

        <div class="row">
          <h1 class="h1 text-center font-weight-bold p-3">நாங்கள் எங்கள் திறன்களில் கவனம் செலுத்துகிறோம்.</h1>
          <p>எங்கள் முக்கிய பலங்களை நாங்கள் அறிவோம், எங்கள் இலக்கோடு ஒட்டிக்கொள்கிறோம், எங்கள் திறமைகளை
            வளர்த்துக் கொள்கிறோம். மிக முக்கியமாக, நாங்கள் கவனம் செலுத்துகிறோம்.</p>
        </div>

        <div class="row text-center p-3">

          <div class="col-12 col-md-4">

            <div class="features_icons_text">
              <fa-icon [icon]="falaptop" class="fa_feature_icons display-4"></fa-icon>
              </div>

              <div class="textwidget pt-4">
                <h5 class="text-westars-sub p-2"> இடைமுக வடிவமைப்பு </h5>

                <p>தடையற்ற வாடிக்கையாளர் அனுபவத்தை உறுதிப்படுத்த பன்முக இயங்குதள வடிவமைப்பில் கவனம்
                  செலுத்துகிறோம்</p>

              </div>
            </div>

          <div class="col-12 col-md-4">

            <div class="">
              <fa-icon [icon]="fapencilruler" class="fa_feature_icons display-4"></fa-icon>
            </div>

            <div class="textwidget pt-4">
              <h5 class="text-westars-sub p-2">
                தனிப்பயன் இடைமுக வடிவமைப்பு </h5>

              <p>இணையற்ற பயனர் ஆர்வத்திற்காக தனிப்பட்ட தனிப்பயன் இடைமுகங்களை உருவாக்குவதில் திறன்
                பெற்றுள்ளோம்</p>

            </div>
          </div>

          <div class="col-12 col-md-4">

            <div class="features_icons_text">
              <fa-icon [icon]="famobilealt" class="fa_feature_icons a display-4"></fa-icon>
            </div>

            <div class="textwidget pt-4">
              <h5  class="text-westars-sub p-2">
                திறன்பேசி பயன்பாடுகள் </h5>

              <p>IOS மற்றும் Android APP மேம்பாடு இரண்டிலும் விரிவான அனுபவம்</p>

            </div>
          </div>

        </div>

      </div>

    </div>
  </div>

  <div class="container-fluid no-gutters mt-4">
    <div class="container">

      <div class="row p-3">

        <div class="col">
          <div class="siteorigin-widget-tinymce textwidget">
            <h1 class="h1 text-center font-weight-bold p-3">நாங்கள் எங்கள் வேலையில்
                பெருமைப்படுகிறோம்.</h1>

            வலை அபிவிருத்தி, விளம்பர வடிவமைப்பு மற்றும் நிறுவன&nbsp; அடையாள மேம்பாடு.
          </div>
        </div>

      </div>

      <div class="row p-3">
        <div class="col-sm-7">
          <div class="card-deck text-center">
            <div class="card">
              <a href="https://www.westars.in/" target="_blank" rel="noopener">
                <img src="assets/img/westars_logo.png" class="card-img-top img img-responsive" alt="...">
                <div class="card-body">
                  <h5 class="card-title">WESTARS</h5>
                  <p class="card-text"></p>
                  <p class="card-text"><small class="text-muted"></small></p>
                </div>
              </a>
            </div>
            <div class="card">
              <a href="https://tssecuritysystem.westars.in" class="" target="_blank" rel="noopener">
                <img src="assets/img/tssecuritysystem_logo_150x122.png" class="card-img-top img img-responsive" alt="...">
                <div class="card-body">
                  <h5 class="card-title">TS SECURITY SYSTEM</h5>
                  <p class="card-text"></p>
                  <p class="card-text"><small class="text-muted"></small></p>
                </div>
              </a>
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>

  <div class="container-fluid no-gutters mt-4 bg-westars-dark">
    <div class="container">

      <div class="row p-3 tell_us">
        <div class="col-12 col-md-7 tell_us_text">
          <h3 class="text-westars-sub font-weight-bold p-2">கூறுங்கள்</h3>

          <h1 class="h1 text-center text-white font-weight-bold p-3">உங்களிடமிருந்து கேட்க நாங்கள் விரும்புகிறோம்.</h1>
        </div>
        <div class="col-12 col-md-5 tell_us_div">
          <a [routerLink]="['/contactus']" class="tell_us_btn btn btn-outline-light more_details m-3 p-3" >
            <span>
              தொடர்பில் இருங்கள் </span>
          </a>
        </div>
      </div>

      <div class="row justify-content-center p-3">

        <div class="d-flex p-3">
          <div class="p-3">
            <a class="row bg-dark text-white" title="allwestars"
            aria-label="allwestars" target="_blank" rel="noopener noreferrer"
            href="https://www.facebook.com/allwestars">

              <div class="features_icons_icon display-4">
                <fa-icon class="fa fa-facebook-square fa_feature_icons"></fa-icon>
              </div>

            </a>
          </div>

          <!-- <div class="p-3">
            <a class="row bg-dark text-white" title="WESTARS on Twitter"
            aria-label="WESTARS on Twitter" target="_blank" rel="noopener noreferrer"
            href="https://twitter.com/">
            <div class="features_icons_icon display-4">
              <fa-icon class="fa fa-twitter-square fa_feature_icons"></fa-icon>
            </div>
            </a>
          </div>

          <div class="p-3">
            <a class="row bg-dark text-white" title="WESTARS on Linkedin"
            aria-label="WESTARS on Linkedin" target="_blank" rel="noopener noreferrer"
            href="https://www.linkedin.com/">
            <div class="features_icons_icon display-4">
              <fa-icon class="fa fa-linkedin-square fa_feature_icons"></fa-icon>
            </div>
            </a>
          </div>

          <div class="p-3">
            <a class="row bg-dark text-white"
            title="WESTARS on Instagram" aria-label="WESTARS on Instagram" target="_blank"
            rel="noopener noreferrer" href="https://instagram.com/">
            <div class="features_icons_icon display-4">
            <fa-icon class="fa fa-instagram fa_feature_icons"></fa-icon>
            </div>
            </a>
          </div> -->

        </div>

      </div>

    </div>
  </div>

</main>




