import { Component, OnInit, HostListener, Inject } from '@angular/core';
import { trigger, state, transition, style, animate } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import { faBars,faPhone,faEnvelope,faUser,faSearch,faAd,faLanguage } from '@fortawesome/free-solid-svg-icons';

import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CookieService } from 'ngx-cookie';
import { tap } from 'rxjs/operators';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public serverUrl = "//localhost/projects/angular/westarsapi/api/";
 // public serverUrl = "api/";

  isLoggedOut: string= localStorage.getItem('isLoggedOut') ? localStorage.getItem('isLoggedOut'):'true';
  isLoggedIn:string=localStorage.getItem('isLoggedIn') ? localStorage.getItem('isLoggedIn'):'false';


  private token=localStorage.getItem('token');

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Bearer '+this.token
    })
  };

  user_id=localStorage.getItem('id');
  user_name=localStorage.getItem('name');
  user_email=localStorage.getItem('email');

  constructor(@Inject(DOCUMENT) document,private http:HttpClient,private router:Router,private SpinnerService: NgxSpinnerService,private cookieService: CookieService) { }

  getCookie(key: string){
    return this.cookieService.get(key);
  }

  ngOnInit(): void {

    //this.http.get('csrf-cookie').subscribe();

    this.http.get<HttpResponse<Object>>('csrf-cookie', {observe: 'response'}).pipe(
      tap(resp => console.log('heaeder', resp.headers.get('ReturnStatus')))
    );


    this.http.get('csrf-cookie', {withCredentials:true}).subscribe(response =>
    {
      //alert('hi '+this.getCookie('XSRF-TOKEN'));
      //console.log(response.headers.get('X-Token'));
      console.log("csfr 1st :");
      console.log(this.cookieService.getAll())

    });


  }

  fabars=faBars;
  faphone=faPhone;
  faenvelope=faEnvelope;
  fafacebook=faUser;
  fasearch=faSearch;
  faad=faAd;
  falanguage=faLanguage;



  navbarCollapsed:boolean=true;
  searchCollapsed:boolean=true;


  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
     if (window.pageYOffset > 550) {
       let element = document.getElementById('main-menu');
       element.classList.add('fixed-top');
     } else {
      let element = document.getElementById('main-menu');
        element.classList.remove('fixed-top');
     }
  }

  userLogout()
  {
    //alert('hi');

    //this.SpinnerService.show();

    //this.user_id=localStorage.getItem('id');

    /* this.isLoggedOut = true;
    this.isLoggedIn = false;

    localStorage.setItem('isLoggedOut', (this.isLoggedOut).toString());
    localStorage.setItem('isLoggedIn', (this.isLoggedIn).toString());

    localStorage.setItem('token', '');

    localStorage.setItem('id', '');
    localStorage.setItem('name', '');
    localStorage.setItem('email', '');

    this.router.navigateByUrl('/myaccount'); */

    const logout_req=this.http.post(this.serverUrl+'logout', {"user_id":this.user_id}, this.httpOptions);

    logout_req.subscribe(
      data  =>
      {

        localStorage.setItem('token', '');
        localStorage.setItem('name', '');
        localStorage.setItem('email', '');

       /*  if(data.status=='success')
        {
          this.isLoggedOut = 'true';
          this.isLoggedIn = 'false';

          localStorage.setItem('isLoggedOut', (this.isLoggedOut).toString());
          localStorage.setItem('isLoggedIn', (this.isLoggedIn).toString());

          localStorage.setItem('token', '');
          localStorage.setItem('name', '');
          localStorage.setItem('email', '');

          this.router.navigateByUrl('/myaccount');
          this.SpinnerService.hide();

        }
        else
        {
          localStorage.setItem('token', '');
          localStorage.setItem('name', '');
          localStorage.setItem('email', '');
        } */
      },
      error  =>
      {


      });


  }

}
