
 <!-- header -->
 <app-header></app-header>
 <app-slider></app-slider>

<!--  <app-navigation></app-navigation> -->

 <!-- routes will be rendered here -->

 <router-outlet></router-outlet>

<!-- footer -->
 <app-footer></app-footer>
