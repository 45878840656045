import { BrowserModule } from '@angular/platform-browser';
import { NgModule, InjectionToken } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { ServicesComponent } from './services/services.component';
import { ContactusComponent } from './contactus/contactus.component';
import { MyaccountComponent } from './myaccount/myaccount.component';
import { RouterModule } from '@angular/router';
//import { NgbModule} from '@ng-bootstrap/ng-bootstrap';

import { HttpClientModule,HttpClientXsrfModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';

import { NavigationComponent } from './navigation/navigation.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SliderComponent } from './slider/slider.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { GoogleMapsModule } from '@angular/google-maps'
import { CookieModule } from 'ngx-cookie';
import { HttpXsrfInterceptor } from './interceptors/httpxsrf.Interceptor';
import {APP_BASE_HREF,DOCUMENT,Location, LocationStrategy, PathLocationStrategy, PlatformLocation   } from '@angular/common';
import { ApiInterceptor } from './interceptors/api.Interceptor';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';

//const APP_BASE_HREF:string=window.location.origin+'/';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutusComponent,
    ServicesComponent,
    ContactusComponent,
    MyaccountComponent,
    NavigationComponent,
    HeaderComponent,
    FooterComponent,
    SliderComponent,
    PagenotfoundComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule.forRoot([
      { path: '', component: HomeComponent },
      { path: 'home', component: HomeComponent },
      { path: 'aboutus',component: AboutusComponent},
      { path: 'services', component: ServicesComponent },
      { path: 'contactus', component: ContactusComponent },
      { path: 'myaccount', component: MyaccountComponent },
      { path: '**', component: PagenotfoundComponent},

    ]),
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'XSRF-TOKEN',
      headerName: 'X-CSRF-TOKEN'
    }),
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgxSpinnerModule,
    GoogleMapsModule,
    CookieModule
    //AgmCoreModule.forRoot({apiKey: 'AIzaSyBphwwHfAyGJeQvN317Mf-XFGVwHxF66iA'})
  ],
  providers: [ /* { provide: HttpXsrfInterceptor, multi: true } */
    HttpXsrfInterceptor,
    /* [{provide: APP_BASE_HREF, useValue: basehref()}], */
   // [{provide: APP_BASE_HREF, useValue: APP_BASE_HREF}],

    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  appTitle="WESTARS – Shine Yourself";


  constructor()
  {
    const origin = this.basehref();
    const APP_BASE_HREF:string=window.location.origin+'/';
  }

  basehref()
  {
    var base_href = window.location.origin+'/';
    switch (base_href)
    {
      case "http://localhost:4200/":
       base_href=base_href;
      break;

      case "http://localhost/":
        base_href=base_href+'projects/angular/westars_dist/';
      break;

      case "https://www.projects.westars.in/":
        base_href=base_href+'dev/westars/';
      break;

      case "https://www.westars.in/":
        base_href=base_href;
      break;

      default:
        break;
    }

    //console.log("base href from app "+base_href);
    //document.getElementsByTagName('base')[0].href=base_href;
    return base_href;
    //document.getElementsByTagName('base')[0].href=base_href;

    }


}
