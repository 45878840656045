import { Component, OnInit } from '@angular/core';
import {NgbCarouselConfig} from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute, ResolveEnd} from '@angular/router';
import { Location } from '@angular/common';
import { ElementArrayFinder } from 'protractor';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  providers: [NgbCarouselConfig]
})
export class SliderComponent implements OnInit {

  curPageTitle='';
  slide_imgs=[];
  /* pageTitle=[{route_path:'',route_path_name:'Home'},{route_path:'aboutus',route_path_name:'About Us'},{route_path:'services',route_path_name:'Services'},{route_path:'contactus',route_path_name:'Contact Us'},{route_path:'myaccount',route_path_name:'My Account'}]; */

  pageTitle=[{route_path:'',route_path_name:'முகப்பு'},{route_path:'aboutus',route_path_name:'நாங்கள்'},{route_path:'services',route_path_name:'சேவைகள்'},{route_path:'contactus',route_path_name:'தொடர்பு'},{route_path:'myaccount',route_path_name:'என் கணக்கு'}];


  constructor(config: NgbCarouselConfig,private router: Router, private activatedRoute:ActivatedRoute,private location: Location) {
    // customize default values of carousels used by this component tree
    config.showNavigationArrows = true;
    config.showNavigationIndicators = true;

  }

  ngOnInit(): void {

    this.router.events.subscribe((routerData) =>
    {
      if(routerData instanceof ResolveEnd)
      {
        this.curPageTitle=routerData.url.replace("/", "");

        this.slide_imgs = [
          {route_path:'',slide_img_name:'home.jpg',slide_title_txt:'கவனம். சிந்திப்பு. வடிவமைப்பு. செதுக்கல்.',slide_sub_txt:'நாங்கள் உங்கள் தேவைகளை கேட்டு சிந்தனையுடன் சுத்திகரிக்கப்பட்ட தீர்வுகளை தருகிறோம்.'},
          {route_path:'aboutus',slide_img_name:'aboutus.jpg',slide_title_txt:'எங்களை பற்றி',slide_sub_txt:'கனவுகளின் உலகத்தைக் காண மற்றவர்களுக்கு உதவுதல்!'},
          {route_path:'services',slide_img_name:'services.jpg',slide_title_txt:'உங்கள் தேவை எங்கள் சேவை ',slide_sub_txt:'உங்களுக்கென தனிப்பட்ட கவனிப்புடன் தீர்வுகளை அளிக்கிறோம்'}
          /* {route_path:'contactus',slide_img_name:'contactus.jpg',slide_title_txt:'',slide_sub_txt:''},
          {route_path:'myaccount',slide_img_name:'myaccount.jpg',slide_title_txt:'',slide_sub_txt:''} */
          ].map((n) =>
            {
              if(this.curPageTitle=='')
              {
                n.slide_img_name=`assets/img/slider/${n.slide_img_name}`;
                return n;
              }
              else if(n.route_path==this.curPageTitle && this.curPageTitle!='')
              {
                n.slide_img_name=`assets/img/slider/${n.slide_img_name}`;
                return n;
              }
              else (this.curPageTitle!='' && n.route_path!=this.curPageTitle)
              {
                return null;
              }
            }
          );

          this.pageTitle.find(e =>
          {
            if(e.route_path == this.curPageTitle)
            {
              this.curPageTitle=e.route_path_name;
              return e.route_path_name;
            }
          });


          this.slide_imgs=this.slide_imgs.filter(x => x != null);
          //console.log(this.slide_imgs);

      }
    });



  }



}
