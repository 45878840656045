import { Injectable, Inject } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest,HttpXsrfTokenExtractor } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

    headerName = 'X-XSRF-TOKEN';
    constructor(private tokenService: HttpXsrfTokenExtractor) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        //const baseUrl = document.getElementsByTagName('base')[0].href;
        var base_href=document.getElementsByTagName('base')[0].href;
        var apiurl="";
        var requrl=req.url;
        var srcfrm="";

        switch (base_href)
        {
          case "http://localhost:4200/":
            //apiurl="http://localhost/projects/angular/westarsapi/api/";
            apiurl="//localhost/projects/angular/westarsapi/api/";
            srcfrm="lcds";
          break;

          case "http://localhost/projects/angular/westars_dist/":
            apiurl="http://localhost/projects/angular/westarsapi/api/";
            srcfrm="lcdts";
          break;

          case "https://www.projects.westars.in/dev/westars/":
            apiurl="https://www.projects.westars.in/dev/westarsapi/api/";
            srcfrm="lvdts";
          break;

          case "https://www.westars.in/":
            apiurl="https://www.westarsapi.westars.in/api/";
            srcfrm="lvps";
          break;

          default:
            break;
        }

        //console.log("base api "+apiurl);

        //console.log(this.getCookie('XSRF-TOKEN'));
        console.log(document.cookie);


        if (req.method === 'GET' || req.method === 'HEAD') {
          //return next.handle(req);
        }

        const token = this.tokenService.getToken() as string;;
        console.log("tkn "+token);


        // Be careful not to overwrite an existing header of the same name.
        if (token !== null && !req.headers.has(this.headerName)) {
          req = req.clone({headers: req.headers.set(this.headerName, token)});
        }
        //return next.handle(req);




        const apiReq = req.clone({ url: `${apiurl}${req.url}`,headers: req.headers.set('srcfrm', `${srcfrm}`)});
        console.log(apiReq);

        return next.handle(apiReq);
    }

    getCookie(name)
    {
      console.log(document.cookie);
      
      if (!document.cookie) {
          return null;
      }

      const xsrfCookies = document.cookie.split(';')
          .map(c => c.trim())
          .filter(c => c.startsWith(name + '='));

      if (xsrfCookies.length === 0) {
          return null;
      }

      return decodeURIComponent(xsrfCookies[0].split('=')[1]);
  }


}
