<main role="main" id="maincontent">

  <div class="container-fluid no-gutters mt-2 p-2">

    <div class="container">

      <div class="row mt-3 p-4 text-center">


        <ngb-accordion class="col-12" [closeOthers]="true" activeIds="static-1">

          <ngb-panel *ngIf="isLoggedIn=='false'" id="static-1">
            <ng-template  ngbPanelTitle  let-opened="opened">
              <span>உள்நுழை</span>
            </ng-template>
            <ng-template ngbPanelContent>
              <form [formGroup]="userLogin" (ngSubmit)="userLoginSubmit()" class="form col-12 mt-2 p-2">
                <div class="form-group row">
                  <label for="user_email" class="col-sm-2 col-form-label">Email</label>
                  <div class="col-sm-10">
                    <input type="email" class="form-control user_email" formControlName="user_email">
                  </div>
                </div>
                <div class="form-group row">
                  <label for="user_password" class="col-sm-2 col-form-label">Password</label>
                  <div class="col-sm-10">
                    <input type="password" class="form-control user_password" id="user_password" formControlName="user_password">
                  </div>
                </div>

                <div class="form-group row">

                  <div class="col-sm-10">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" id="gridCheck1">
                      <label class="form-check-label" for="gridCheck1">
                        Remember me
                      </label>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-sm-10">
                    <button type="submit" class="btn btn-primary" [disabled]="!userLogin.valid">Sign in</button>
                  </div>

                </div>
              </form>
            </ng-template>
          </ngb-panel>

          <ngb-panel *ngIf="isLoggedIn=='false'" id="static-2">
            <ng-template  ngbPanelTitle>
              <span>கடவுச்சொல் மறந்துவிட்டதா</span>
            </ng-template>
            <ng-template ngbPanelContent>

              <form [formGroup]="userPwdReset"  (ngSubmit)="userPwdResetSubmit()"  class="form col-12 mt-2 p-2">
                <div class="form-group row">
                  <label for="inputEmail3" class="col-sm-2 col-form-label">Email</label>
                  <div class="col-sm-10">
                    <input type="email" class="form-control" formControlName="user_email">
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-sm-10">
                    <button type="submit" class="btn btn-primary" [disabled]="!userPwdReset.valid">Send Password</button>
                  </div>

                </div>

              </form>

            </ng-template>

          </ngb-panel>

          <ngb-panel *ngIf="isLoggedIn=='false'" id="static-3">
            <ng-template ngbPanelTitle>
              <span>கணக்கு பதிவு</span>
            </ng-template>
            <ng-template ngbPanelContent>

              <form [formGroup]="userRegister" (ngSubmit)="userRegisterSubmit()" class="form col-12 mt-2 p-2">
                <div class="form-group row">
                  <label for="inputEmail3" class="col-sm-2 col-form-label">Name</label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" formControlName="user_name">
                    <div *ngIf="userRegister.controls.invalid && (userRegister.controls.user_name.dirty || userRegister.controls.user_name.touched)" class="alert alert-danger">
                      <div *ngIf="userRegister.controls.user_name.errors.required">
                        User Name is required.
                      </div>

                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="inputEmail3" class="col-sm-2 col-form-label">Email</label>
                  <div class="col-sm-10">
                    <input type="email" class="form-control" formControlName="user_email">
                  </div>
                </div>
                <div class="form-group row">
                  <label for="inputPassword3" class="col-sm-2 col-form-label">Password</label>
                  <div class="col-sm-10">
                    <input type="password" class="form-control" id="inputPassword3" formControlName="user_password">
                  </div>
                </div>

                <div class="form-group row">
                  <label for="inputPassword3" class="col-sm-2 col-form-label">Conform Password</label>
                  <div class="col-sm-10">
                    <input type="password" class="form-control" id="inputPassword3" formControlName="user_conform_password">
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-sm-10">
                    <button type="submit" [disabled]="!userRegister.valid">Sign Up</button>
                  </div>

                </div>

              </form>

            </ng-template>
          </ngb-panel>

          <ngb-panel *ngIf="isLoggedIn=='false'" id="static-4">
            <ng-template  ngbPanelTitle  let-opened="opened">
              <span>கடவுச்சொல் மாற்று</span>
            </ng-template>
            <ng-template ngbPanelContent>

              <form [formGroup]="userUpdate"  (ngSubmit)="userUpdateSubmit()"  class="form col-12 mt-2 p-2">

                <div class="form-group row">
                  <label for="user_password" class="col-sm-2 col-form-label">Current Password</label>
                  <div class="col-sm-10">
                    <input type="password" class="form-control user_password" id="user_password" formControlName="user_password">
                  </div>
                </div>

                <!-- <div class="form-group row">
                  <label for="user_password" class="col-sm-2 col-form-label">Current Password</label>
                  <div class="col-sm-10">
                    <input type="password" class="form-control user_password" id="user_password" formControlName="user_password">
                  </div>
                </div> -->

                <div class="form-group row">
                  <label for="user_password" class="col-sm-2 col-form-label">New Password</label>
                  <div class="col-sm-10">
                    <input type="password" class="form-control user_new_password" id="user_new_password" formControlName="user_new_password">
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-sm-10">
                    <button type="submit" class="btn btn-primary" [disabled]="!userUpdate.valid">Change Password</button>
                  </div>

                </div>

              </form>

            </ng-template>

          </ngb-panel>

          <ngb-panel *ngIf="isLoggedIn=='false'" id="static-5">
            <ng-template ngbPanelTitle>
              <span>கணக்கு விவரங்கள்</span>
            </ng-template>
            <ng-template ngbPanelContent>

              <form [formGroup]="userRegister" (ngSubmit)="userRegisterSubmit()" class="form col-12 mt-2 p-2">
                <div class="form-group row">
                  <label for="inputEmail3" class="col-sm-2 col-form-label">Name</label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" formControlName="user_name">
                    <div *ngIf="userRegister.controls.invalid && (userRegister.controls.user_name.dirty || userRegister.controls.user_name.touched)" class="alert alert-danger">
                      <div *ngIf="userRegister.controls.user_name.errors.required">
                        User Name is required.
                      </div>

                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="inputEmail3" class="col-sm-2 col-form-label">Email</label>
                  <div class="col-sm-10">
                    <input type="email" class="form-control" formControlName="user_email">
                  </div>
                </div>
                <div class="form-group row">
                  <label for="inputPassword3" class="col-sm-2 col-form-label">Password</label>
                  <div class="col-sm-10">
                    <input type="password" class="form-control" id="inputPassword3" formControlName="user_password">
                  </div>
                </div>

                <div class="form-group row">
                  <label for="inputPassword3" class="col-sm-2 col-form-label">Conform Password</label>
                  <div class="col-sm-10">
                    <input type="password" class="form-control" id="inputPassword3" formControlName="user_conform_password">
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-sm-10">
                    <button type="submit" [disabled]="!userRegister.valid">Update</button>
                  </div>

                </div>

              </form>

            </ng-template>
          </ngb-panel>

        </ngb-accordion>


      </div>

    </div>

  </div>

</main>
