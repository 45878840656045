<main role="main" id="maincontent">

  <div class="continer-fluid no-gutters mt-4  p-3 ">

    <div class="container">

      <div class="row mt-4 p-3 text-left">

        <div class="col-12">

            <h1 class="text-westars-head font-weight-bold">நீங்கள் இதுவரை செல்லாத இடத்திற்கு இட்டுச்செல்கிறோம்</h1>
            <h3 class="">உங்களுக்காக நாங்கள் எப்போதும் இல்லாத இடங்களை நாங்கள்
              எடுத்துக்கொள்கிறோம். புதிய அனுபவத்தை தருகிறோம் அன்பினை பெறுகிறோம்.</h3>
        </div>

        <div class="col-12">

          <a [routerLink]="['/contactus']" class="btn btn-outline-info more_details mt-4 p-3">
            <span>இப்போது விசாரிக்கவும் </span>
          </a>

        </div>

      </div>

    </div>

  </div>

  <div class="continer-fluid no-gutters mt-4 p-3 bg-westars-blue text-right">

    <div class="container">

      <div class="row mt-4 p-3">

        <div class="col-12 p-3">
          <h1 class="font-weight-bold">நீங்கள் இதுவரை பார்த்திராத விஷயங்களை நாங்கள் காண்பிப்போம்</h1>
          <h3 class="text-white">சிறந்த திறன்வாய்ந்த படைப்புகளை தருவதில் முனைப்புடன்
            இருக்கிறோம்.உங்களின் அனைத்து வியாபர தீர்வுகளும் எங்களால் சிறந்த வகையில் எளிய முறையில்
            அளிக்கப்படும்</h3>
        </div>

        <div class="col-12 p-3">
          <a [routerLink]="['/services']" class="btn btn-outline-light more_details p-3">
            <span>மேலும் காண்க </span>
          </a>
        </div>

      </div>

    </div>

  </div>

  <div class="continer-fluid no-gutters mt-4  p-3 ">

    <div class="container">


      <div class="row mt-4 p-3">

        <div class="col text-center">
          <h1 class="h1 text-center text-westars-head font-weight-bold p-3">உங்களை அழைத்துச் செல்லும் எங்களை சந்திக்கவும்</h1>
          <h3 class="">எங்கள் வல்லுனரை காணுங்கள்</h3>
        </div>

      </div>

      <div class="row mt-4 p-3">
        <div class="col-sm-4 mx-auto">

          <div class="card-deck mx-auto">
            <div class="card p-2">
              <img src="assets/img/sudhagar.png" class="card-img-top" alt="...">
              <div class="card-body">
                <h5 class="card-title">ஆ.சுதாகர்</h5>
                <h6 class="card-subtitle mb-2 text-muted">உங்கள் வழித்துணை</h6>
                <p class="card-text">உங்கள் கனவை நோக்கி உங்களை நகர்த்தி செல்வோம். </p>
                <!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
              </div>
            </div>
            <!-- <div class="card p-2">
              <img src="assets/img/radhika.jpg" class="card-img-top" alt="...">
              <div class="card-body">
                <h5 class="card-title">ராதிகா</h5>
                <h6 class="card-subtitle mb-2 text-muted">சாதனை இணை ஒருங்கிணைப்பாளர்</h6>
                <p class="card-text">அனைத்து சவால்களிலும் உங்களுடன் இணைந்து பயணிப்போம்.</p>
                <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
              </div>
            </div>
            <div class="card p-2">
              <img src="assets/img/anitha.jpg" class="card-img-top" alt="...">
              <div class="card-body">
                <h5 class="card-title">அனிதா</h5>
                <h6 class="card-subtitle mb-2 text-muted">சாதனை பயிற்றுவிப்பாளர்</h6>
                <p class="card-text">உங்கள் தொழிலை மேம்படுத்த உங்களுடன் உழைப்போம்.</p>
                <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
              </div>
            </div> -->
          </div>

        </div>

      </div>

    </div>

  </div>

</main>
