<main role="main" id="maincontent">

  <div class="container-fluid no-gutters mt-2 p-2">

    <div class="container">

      <div class="row mt-2 p-2 text-center">

        <h1 class="col-12 text-westars-head font-weight-bold">வாருங்கள் பேசலாம்…</h1>
        <h2 class="">உங்கள் தேவையை கேட்க ஆவலாக உள்ளோம். என்ன உதவி வேண்டும்
          உங்களுக்கு?</h2>
      </div>

    </div>

  </div>

  <div class="container-fluid no-gutters mt-2 p-2">

    <div class="container">

      <div class="row mt-2 p-2 text-center">

        <div class="card-deck">
          <div class="card">
            <div class="features_icons_icon display-4 text-westars-brown">
              <fa-icon class="fa_feature_icons fa fa-mobile"></fa-icon>
            </div>
            <div class="card-body">
              <h5 class="card-title text-westars-sub">எங்களை அழைக்கவும்</h5>
              <p class="card-text "><a href="tel:9043135096">9043135096</a></p>
              <!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
            </div>
          </div>
          <div class="card">
            <div class="features_icons_icon display-4 text-westars-brown">
              <fa-icon class="fa_feature_icons fa fa-map-marker"></fa-icon>
            </div>
            <div class="card-body">
              <h5 class="card-title text-westars-sub">எங்களை காண வாருங்கள் </h5>

              <p class="card-text">3/678 தாமரை இல்லம்,
                சோழபுரம், ம.பொடையூர்,
                திட்டக்குடி வட்டம்,
                கடலூர் மாவட்டம், தமிழ்நாடு, 606108,
                இந்தியா.</p>
              <!-- p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
            </div>
          </div>
          <div class="card">
            <div class="features_icons_icon display-4 text-westars-brown">
              <fa-icon class="fa_feature_icons fa fa-envelope"></fa-icon>
            </div>
            <div class="card-body">
              <h5 class="card-title text-westars-sub">எங்களுக்கு மின்னஞ்சல் அனுப்புங்கள்</h5>
              <p class="card-text"><a href="mailto:contact@westars.in">contactus@westars.in</a></p>
              <!-- <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>

  <div class="container-fluid no-gutters mt-2 p-2">

    <div class="container">

      <div class="row mt-2 p-2 text-center">

        <div class="col-12 col-md-6 mt-2 p-2 map" id="map" style="width: 100%; height: 50vh;"></div>

        <!-- <google-map class="col-12 col-md-6 mt-2 p-2" height="500px" width="100%" [zoom]="zoom" [center]="center" [options]="options"
        (mapClick)="click($event)">
          <map-marker
            #markerElem
            *ngFor="let marker of markers"
            [position]="marker.position"
            [label]="marker.label"
            [title]="marker.title"
            [options]="marker.options"
            (mapClick)="openInfo(markerElem, marker.info)"
          >
          </map-marker>
          <map-info-window>{{ infoContent }}</map-info-window>
        </google-map> -->

        <!-- <button (click)="zoomIn()">Zoom in</button>
        <button (click)="zoomOut()">Zoom out</button>
        <button (click)="logCenter()">Log center</button>
        <button (click)="addMarker()">Add marker</button> -->

        <div class="col-12 col-md-6 mt-2 p-2">
          <div class="textwidget">
            <h5 class="text-westars-sub">தகவலுக்கு </h5>

            <div role="form" class="wpcf7 form" id="wpcf7-f53-p40-o1" lang="ta-IN" dir="ltr">
              <div class="screen-reader-response">

                <ng-template #longContent let-modal>
                  <div class="modal-header">
                    <h4 class="modal-title">Mail Sent</h4>
                    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <div [innerHtml]="mailhtml"></div>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-light" (click)="modal.close('Close click')">Close</button>
                  </div>
                </ng-template>

              </div>

              <form [formGroup]="userContactusMail" (ngSubmit)="userContactusMailSubmit()" class="form col-12 mt-2 p-2">
                <div class="form-group row">
                  <label for="user_name" class="col-sm-3 col-form-label">பெயர்</label>
                  <div class="col-sm-9">
                    <input type="text" class="form-control user_name" formControlName="user_name" id="user_name">
                    <div *ngIf="userContactusMail.controls.invalid && (userContactusMail.controls.user_name.dirty || userContactusMail.controls.user_name.touched)" class="alert alert-danger">
                      <div *ngIf="userContactusMail.controls.user_name.errors.required">
                        User Name is required.
                      </div>

                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <label for="user_email" class="col-sm-3 col-form-label">மின்னஞ்சல்</label>
                  <div class="col-sm-9">
                    <input type="email" class="form-control user_email" id="user_email" formControlName="user_email">
                  </div>
                </div>
                <div class="form-group row">
                  <label for="user_msg_title" class="col-sm-3 col-form-label">தலைப்பு</label>
                  <div class="col-sm-9">
                    <input type="text" class="form-control user_msg_title" id="user_msg_title" formControlName="user_msg_title">
                  </div>
                </div>

                <div class="form-group row">
                  <label for="inputPassword3" class="col-sm-3 col-form-label">தகவல்</label>
                  <div class="col-sm-9">
                    <textarea class="form-control user_msg_txt" id="user_msg_txt" formControlName="user_msg_txt"></textarea>
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-sm-9">
                    <button type="submit" [disabled]="!userContactusMail.valid">அனுப்பு</button>
                  </div>

                </div>

              </form>
            </div>
          </div>
        </div>

      </div>

    </div>

  </div>

</main>
