import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CookieModule } from 'ngx-cookie';

const routes: Routes = [];

@NgModule({
  //imports: [RouterModule.forRoot(routes),AgmCoreModule.forRoot({apiKey: 'AIzaSyBphwwHfAyGJeQvN317Mf-XFGVwHxF66iA'})],
  imports: [RouterModule.forRoot(routes),CookieModule.forRoot()],
  exports: [RouterModule,CookieModule]

})
export class AppRoutingModule { }
