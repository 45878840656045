<main role="main" id="maincontent">

  <div class="container-fluid no-gutters mt-4 p-3 bg-light">

    <div class="container">

      <div class="row mt-2 p-2 text-center">
        <h1 class="text-westars-head font-weight-bold">எங்களுடன் ஒரு திட்டத்தைத் திட்டமிடவா?
            <a style="color: #f39c12;" [routerLink]="['/contactus']" rel="noopener">
              <span class="" style="vertical-align: inherit;">மேற்கோள் கிடைக்கும்</span>
            </a>
        </h1>
      </div>

    </div>

  </div>

  <div class="container-fluid no-gutters mt-4 p-3">

    <div class="container">

      <div class="row mt-2 p-2 ">

        <div class="card-deck">
          <div class="card">
            <img src="https://layouts.siteorigin.com/wp-content/uploads/2015/08/crane-homes-366562_640.jpg#640x404" class="card-img-top" alt="...">
            <div class="card-body">
              <h3 class="card-title text-westars-sub">கட்டுமான கலை மற்றும் வடிவமைப்பு</h3>
              <p class="card-text">சிறந்த இணைய தள கட்டுமான கருவிகளும், கட்டுமானவர்களும் இங்குள்ள சிறப்பு</p>
              <p class="card-text"><small class="text-muted">
                <a [routerLink]="['/contactus']" ><span style="color: #f39c12;"><span class="goog-text-highlight">மேலும் கண்டுபிடிக்க</span></span></a></small></p>
            </div>
          </div>
          <div class="card">
            <img src="https://layouts.siteorigin.com/wp-content/uploads/2015/08/architect-569361_640-e1438971586695.jpg#640x404" class="card-img-top" alt="...">
            <div class="card-body">
              <h3 class="card-title text-westars-sub">திட்டமிடல்</h3>
              <p class="card-text">
                இலக்கு நோக்கிய கூர்மையான திட்டமிடல், அதை நோக்கிய வேகமான நகர்வு</p>
              <p class="card-text"><small class="text-muted">
                <a [routerLink]="['/contactus']" ><span style="color: #f39c12;"><span class="goog-text-highlight">மேலும் கண்டுபிடிக்க</span></span></a>
              </small></p>
            </div>
          </div>
          <div class="card">
            <img src="https://layouts.siteorigin.com/wp-content/uploads/2015/08/men-827301_640-e1438971837916.jpg#640x404" class="card-img-top" alt="...">
            <div class="card-body">
              <h3 class="card-title text-westars-sub">திட்ட மேலாண்மை</h3>
              <p class="card-text">
                உங்களுக்கான திட்டத்தை பல கட்டங்களாக வகுத்து, சிறந்த சட்டங்களுடன் வானில் பறக்கும் பட்டங்களாக்குவதே எங்கள் வழி.</p>
              <p class="card-text"><small class="text-muted">
                <a [routerLink]="['/contactus']" ><span style="color: #f39c12;"><span class="goog-text-highlight">மேலும் கண்டுபிடிக்க</span></span></a>
              </small></p>
            </div>
          </div>
        </div>

      </div>

    </div>

  </div>

  <div class="container-fluid no-gutters mt-4 p-3 bg-light text-center">

    <div class="container">

      <div class="row mt-3 p-3">

        <div class="card-columns">

          <div class="card">
            <div class="features_icons_icon display-4 text-westars-brown">
              <fa-icon class="fa_feature_icons fa fa-home"></fa-icon>
            </div>
            <div class="card-body">
              <h5 class="card-title text-westars-sub">இணைய தள வடிவமைப்பு மற்றும் விரிவாக்கம்</h5>
              <p class="card-text">உங்களின் அனைத்து வகை இணைய தேவைகளுக்கும் இணையதள வடிவமைப்பு மற்றும் விரிவாக்கம்.</p>
            </div>
          </div>

          <div class="card">
            <div class="features_icons_icon display-4 text-westars-brown">
              <fa-icon class="fa_feature_icons fa fa-snowflake-o"></fa-icon>
            </div>
            <div class="card-body">
              <h5 class="card-title text-westars-sub">இணைய தள வடிவமைப்பு</h5>
              <p class="card-text">இணைய தள வடிவமைப்பு மட்டும்.</p>
            </div>

          </div>

          <div class="card">
            <div class="features_icons_icon display-4 text-westars-brown">
              <fa-icon class="fa_feature_icons fa fa-building"></fa-icon>
            </div>
            <div class="card-body">
              <h5 class="card-title text-westars-sub">இணைய/கணினி பயன்பாடுகள்</h5>
              <p class="card-text">அனைத்து வகை தொழில்களுக்கான  இணைய/கணினி பயன்பாடுகள்

                .</p>
            </div>
          </div>

          <div class="card">
            <div class="features_icons_icon display-4 text-westars-brown">
              <fa-icon class="fa_feature_icons fa fa-mobile"></fa-icon>
            </div>
            <div class="card-body">
              <h5 class="card-title text-westars-sub">திறன்பேசி பயன்பாடுகள்</h5>
              <p class="card-text">ஆண்ட்ராய்ட்(Android), ஐஓஎஸ் (IOS) திறன்பேசிகளுக்கான பயன்பாடுகள்.</p>
            </div>
          </div>

          <div class="card">
            <div class="features_icons_icon display-4 text-westars-brown">
              <fa-icon class="fa_feature_icons fa fa-connectdevelop"></fa-icon>
            </div>
            <div class="card-body">
              <h5 class="card-title text-westars-sub">இணைய சேவைகள்
              </h5>
              <p class="card-text">இணைய தளத்திற்கான/பயன்பாடுகளுக்கான  இணைய சேவைகள்(Web Services).</p>
            </div>
          </div>

          <div class="card">
            <div class="features_icons_icon display-4 text-westars-brown">
              <fa-icon class="fa_feature_icons fa fa-trademark"></fa-icon>
            </div>
            <div class="card-body">
              <h5 class="card-title text-westars-sub">வியாபார சின்னம் வடிவமைப்பு</h5>
              <p class="card-text">
                விரும்பும் வகையில் வியாபாரத்திற்கான குறியீட்டு சின்ன வடிவமைப்பு.</p>
            </div>
          </div>

          <div class="card">
            <div class="features_icons_icon display-4 text-westars-brown">
              <fa-icon class="fa_feature_icons fa fa-address-card"></fa-icon>
            </div>
            <div class="card-body">
              <h5 class="card-title text-westars-sub">விளவம்பர வடிவமைப்புகள்</h5>
              <p class="card-text">அச்சு, ஒலி மற்றும் காணொலி வடிவங்கள்.</p>
            </div>
          </div>

          <div class="card">
            <div class="features_icons_icon display-4 text-westars-brown">
              <fa-icon class="fa_feature_icons fa fa-language"></fa-icon>
            </div>
            <div class="card-body">
              <h5 class="card-title text-westars-sub">உள்ளடக்க உருவாக்கம் மற்றும் மொழிபெயர்ப்பு</h5>
              <p class="card-text">
                தமிழ் மற்றும் ஆங்கிலம்.</p>
            </div>
          </div>


        </div>

      </div>

    </div>

  </div>

  <div class="container-fluid no-gutters mt-4 p-3 text-center">

    <div class="container">

      <div class="row text-center">

        <div class="pt-2">
          <h1 class="text-westars-head font-weight-bold">“நான் கண்ட நல்ல இணைய தள வடிவமைப்பு கட்டுமான நிறுவனங்களில்
            ஒன்று”</h1>
          <h3 class="p-3"> ~ தி.சரவணன் ஆலம்பாடி</h3>
        </div>

      </div>

    </div>

  </div>

  <div class="container-fluid no-gutters mt-2 p-2 text-center">

    <div class="container">

      <div class="row p-3">
        <!-- <div class="col-sm-7"> -->
          <h3 class="col-12 text-left">சமீபத்திய திட்டங்கள்</h3>
          <div class="col-sm-7">

            <div class="card-deck">
              <div class="card">
                <a href="https://www.westars.in/" target="_blank" rel="noopener">
                  <img src="assets/img/westars_logo.png" class="card-img-top img img-responsive" alt="...">
                  <div class="card-body">
                    <h5 class="card-title">WESTARS</h5>
                    <p class="card-text"></p>
                    <p class="card-text"><small class="text-muted"></small></p>
                  </div>
                </a>
              </div>
              <div class="card">
                <a href="https://tssecuritysystem.westars.in" class="" target="_blank" rel="noopener">
                  <img src="assets/img/tssecuritysystem_logo_150x122.png" class="card-img-top img img-responsive" alt="...">
                  <div class="card-body">
                    <h5 class="card-title">TS SECURITY SYSTEM</h5>
                    <p class="card-text"></p>
                    <p class="card-text"><small class="text-muted"></small></p>
                  </div>
                </a>
              </div>
              <!-- <div class="card">
                <img src="assets/img/westars_logo_100x81.png" class="card-img-top img img-responsive" alt="...">
                <div class="card-body">
                  <h5 class="card-title">தொடர்பு</h5>
                  <p class="card-text"></p>
                  <p class="card-text"><small class="text-muted"></small></p>
                </div>
              </div> -->
            </div>

          </div>
        </div>

      <!-- </div> -->

    </div>

  </div>

  <div class="container-fluid no-gutters mt-2 p-2 text-center bg-westars-dark">

    <div class="container">

      <div class="row mt-2 p-2 justify-content-center">

        <h1 class="text-westars-head font-weight-bold">எங்களை தொடர்பு கொள்ளுங்கள்</h1>
        <div class="decoration">
          <div class="decoration-inside"></div>
        </div>

      </div>

      <div class="row justify-content-center p-3">

        <div class="d-flex p-3">
          <div class="p-3">
            <a class="row bg-dark text-white" title="allwestars"
            aria-label="allwestars" target="_blank" rel="noopener noreferrer"
            href="https://www.facebook.com/allwestars">

              <div class="features_icons_icon display-4">
                <fa-icon class="fa fa-facebook-square fa_feature_icons"></fa-icon>
              </div>

            </a>
          </div>

          <!-- <div class="p-3">
            <a class="row bg-dark text-white" title="WESTARS on Twitter"
            aria-label="WESTARS on Twitter" target="_blank" rel="noopener noreferrer"
            href="https://twitter.com/">
            <div class="features_icons_icon display-4">
              <fa-icon class="fa fa-twitter-square fa_feature_icons"></fa-icon>
            </div>
            </a>
          </div>

          <div class="p-3">
            <a class="row bg-dark text-white" title="WESTARS on Linkedin"
            aria-label="WESTARS on Linkedin" target="_blank" rel="noopener noreferrer"
            href="https://www.linkedin.com/">
            <div class="features_icons_icon display-4">
              <fa-icon class="fa fa-linkedin-square fa_feature_icons"></fa-icon>
            </div>
            </a>
          </div>
          <div class="p-3">
            <a class="row bg-dark text-white"
            title="WESTARS on Instagram" aria-label="WESTARS on Instagram" target="_blank"
            rel="noopener noreferrer" href="https://instagram.com/">
            <div class="features_icons_icon display-4">
            <fa-icon class="fa fa-instagram fa_feature_icons"></fa-icon>
            </div>
            </a>
          </div> -->

        </div>

      </div>

    </div>

  </div>

</main>
