import { Component, OnInit } from '@angular/core';
import { faHandHoldingHeart,faLightbulb,faPalette,faSyncAlt,faLaptop,faPencilRuler,faMobileAlt } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor() {

  }

  ngOnInit(): void {
  }

  faheart=faHandHoldingHeart;
  falightbulb=faLightbulb;
  fapalette=faPalette;
  fasyncalt=faSyncAlt;
  falaptop=faLaptop;
  fapencilruler=faPencilRuler;
  famobilealt=faMobileAlt;
  /*
  faphone=faPhone;
  faenvelope=faEnvelope;
  fafacebook=faUser;
  fasearch=faSearch; */


}
