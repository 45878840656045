<div class="container-fluid no-gutters p-0">

    <h1 class="text-westars-head font-weight-bold">{{ curPageTitle}}</h1>

    <ngb-carousel *ngIf="slide_imgs">
      <ng-template ngbSlide *ngFor="let slide_img of slide_imgs">
        <div class="page_slider" *ngIf="slide_img!=''">
          <div class="picsum-img-wrapper bg-dark">
            <img class="slider_img img-responsive" [src]="slide_img.slide_img_name" alt="Westars Slide Images">
          </div>
          <div class="carousel-caption">
            <h1 class="text-westars-head font-weight-bold">{{slide_img.slide_title_txt}}</h1>
            <p>{{slide_img.slide_sub_txt}}</p>
          </div>
        </div>
      </ng-template>
    </ngb-carousel>

</div>

