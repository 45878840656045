import { Component, OnInit, ViewChild, Inject } from '@angular/core'
import { FormBuilder,FormGroup,FormControl,Validators } from '@angular/forms';
import { HttpClient, HttpHeaders,HttpClientXsrfModule } from '@angular/common/http';
import { Router } from '@angular/router';

import 'ol/ol.css';
import Map from 'ol/Map';
import OSM from 'ol/source/OSM';
import View from 'ol/View';
import XYZ from 'ol/source/XYZ';
import {FullScreen, defaults as defaultControls} from 'ol/control';
import {  DragRotateAndZoom,  defaults as defaultInteractions} from 'ol/interaction';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import Select from 'ol/interaction/Select';
import Stamen from 'ol/source/Stamen';
import VectorSource from 'ol/source/Vector';
import {Icon, Style,Fill,  Stroke,Text} from 'ol/style';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
import { DomSanitizer } from '@angular/platform-browser';
//import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DOCUMENT } from '@angular/common';
//import { APP_BASE_HREF, DOCUMENT } from '@angular/common';


@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss']
})


export class ContactusComponent implements OnInit {
  [x: string]: any;

  public serverUrl = "//localhost/projects/angular/westarsapi/api/";
  //westarsapi.westars.in
  //public serverUrl = "api/";

  private token=localStorage.getItem('token');
  private csrf_token=localStorage.getItem('csrf_token');

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': 'Basic '+this.token,
      //'X-CSRF-TOKEN': 'eyJpdiI6ImRFdWc4cDhBOGZ6R2IrRGtSb2tSaVE9PSIsInZhbHVlIjoidjJMUHVINnptRHc4V1FsYWdLRzFNZ1laZXRJKzc1YU93MzAxaWY4bGxLNERySlFnTytHM2hHaGFzUTZ3aWFtQSIsIm1hYyI6IjkyYzRlMDZhMTdlMTMxMmNmMTRiYjU3Mzk0ZGEzNTlhYWUzYWI3NDU4YTVhMmMzZGVlOTIzZTYzM2M0MzY0ZmQifQeyJpdiI6ImRFdWc4cDhBOGZ6R2IrRGtSb2tSaVE9PSIsInZhbHVlIjoidjJMUHVINnptRHc4V1FsYWdLRzFNZ1laZXRJKzc1YU93MzAxaWY4bGxLNERySlFnTytHM2hHaGFzUTZ3aWFtQSIsIm1hYyI6IjkyYzRlMDZhMTdlMTMxMmNmMTRiYjU3Mzk0ZGEzNTlhYWUzYWI3NDU4YTVhMmMzZGVlOTIzZTYzM2M0MzY0ZmQifQ',
      //'withCredentials': true
    })
  };


  lat: number = 11.488316;
  lng: number = 79.038431;
  zoom: number = 13;

  ol: any;
  map: any;

  public mailhtml: any = "";
  @ViewChild('content') private content;

  constructor(private fb: FormBuilder,private http:HttpClient,private router:Router, private domsanitizer:DomSanitizer,private modalService: NgbModal,@Inject(DOCUMENT) private document: Document) {

    const origin = this.document.location.origin;

   }


  ngOnInit(): void {

    //alert(APP_BASE_HREF);


    //this.http.get('csrf-cookie').subscribe();

    function createStyle(src, img) {
      return new Style({
        image: new Icon({
          anchor: [0.5, 0.5],
          crossOrigin: 'anonymous',
          src: src,
          img: img,
          imgSize: img ? [1, 1] : undefined,
          scale: 0.3,
          //color: '#ec1010',
        }),
        text: new Text({
          text: 'WESTARS',
          scale: [1.5, 1.5 ],
          //rotation: Math.PI / 6,
          textAlign: 'center',
          textBaseline: 'middle',
          //Fill: '#ec1010',
          fill: new Fill({
            color: 'white'
        }),
        stroke: new Stroke({
            color: 'black',
            lineCap: 'butt',
            width: 4
        }),
        offsetX: 0,
            offsetY: 60,

        }),

      });
    }


    var iconFeature = new Feature(new Point([this.lng,this.lat]));
        iconFeature.set('style', createStyle('assets/img/westars_logo.png', undefined));


    var map= new Map({
      controls: defaultControls().extend([new FullScreen()]),
      interactions: defaultInteractions().extend([new DragRotateAndZoom()]),
      target: 'map',
      layers: [
        new TileLayer({
          source: new XYZ({
            url: 'http://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}'
          })
        }),
        new VectorLayer({
          style: function (feature) {
            return feature.get('style');
          },
          source: new VectorSource({features: [iconFeature]}),
        })

      ],
      view: new View({
        center: [ this.lng,this.lat],
        projection: 'EPSG:4326',
        zoom: this.zoom,
        rotate:true
      })
    });



    var selectStyle = {};
    var select = new Select({
      style: function (feature) {
        var image = feature.get('style').getImage().getImage();
        if (!selectStyle[image.src]) {
          var canvas = document.createElement('canvas');
          var context = canvas.getContext('2d');
          /* canvas.width = image.width;
          canvas.height = image.height;
          context.drawImage(image, 0, 0, image.width, image.height); */
          canvas.width = 5;
          canvas.height = 5;
          context.drawImage(image, 0, 0, .5, .5);
          var imageData = context.getImageData(1, 1, canvas.width, canvas.height);
          var data = imageData.data;
          for (var i = 0, ii = data.length; i < ii; i = i + (i % 4 == 2 ? 2 : 1)) {
            data[i] = 255 - data[i];
          }
          context.putImageData(imageData, 0, 0);
          selectStyle[image.src] = createStyle(undefined, canvas);
        }
        return selectStyle[image.src];
      },
    });
    map.addInteraction(select);

    map.on('pointermove', function (evt) {
      map.getTargetElement().style.cursor = map.hasFeatureAtPixel(evt.pixel)
        ? 'pointer'
        : '';
    });

    map.on('singleclick', function(e) {
    var feature = map.forEachFeatureAtPixel(e.pixel, function(feature) {
      return feature;
    });

    //console.log(feature);

    if(feature instanceof Feature){

        //map.getView().fit(feature.getGeometry().getExtent());

        var view = map.getView();
        //var zoom = view.getZoom();
        view.setZoom(18);
        //this.VectorLayer.redraw();

        //VectorLayer.clear();

        //map.redraw();

    }

  });


  }


  userContactusMail = new FormGroup({
    user_name: new FormControl('',Validators.required),
    user_email: new FormControl('',Validators.email),
    user_msg_title : new FormControl('',Validators.required),
    user_msg_txt: new FormControl('',Validators.required),
  });

  userContactusMailSubmit()
  {


    //console.warn(this.userContactusMail.status);
    console.warn(this.userContactusMail.value);


    this.http.get<any>('csrf-cookie').subscribe(() => {

      this.http.post<any>('contactusmail', this.userContactusMail.value, this.httpOptions)
          .subscribe(success => {

            console.log(success);


            this.mailhtml = this.domsanitizer.bypassSecurityTrustHtml(success.html); // this line bypasses angular security
            //this.modalService.open(this.content);
            //this.openScrollableContent(this.content)
            this.userContactusMail.reset();
            alert(success.msg);
          },
          error => {
              console.log(error)
              alert('Hi '+this.userContactusMail.value.user_name+' Your Mail Sent Successfully');
            })

 })

    /* const res=this.http.post('contactusmail', this.userContactusMail.value, this.httpOptions).subscribe(dt =>
    {

      console.log(dt);
      //localStorage.setItem('user', JSON.stringify(dt));
    }); */


  }


  openScrollableContent(longContent) {
    this.modalService.open(longContent, { scrollable: true });
  }


}
