<nav id="main-menu" class="navbar navbar-expand-md navbar-dark bg-dark" (scroll)="onWindowScroll($event);">
  <a class="navbar-brand" [routerLink]="['/']" (click)="navbarCollapsed = true">
    <img src="assets/img/westars_logo_100x81.png" class="img img-responsive"></a>
    <button class="navbar-toggler navbar-toggler-right" type="button"
    (click)="navbarCollapsed = !navbarCollapsed" [attr.aria-expanded]="!navbarCollapsed"
    aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" [ngbCollapse]="navbarCollapsed" id="navbarTogglerDemo02">
    <ul class="navbar-nav mr-auto">

      <li class="nav-item" [routerLinkActive]="['active']">
        <a class="nav-link" [routerLink]="['/']" (click)="navbarCollapsed = true">முகப்பு</a>
      </li>

      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/aboutus']" (click)="navbarCollapsed = true">நாங்கள்</a>
      </li>

      <li class="nav-item">
        <a class="nav-link"  [routerLink]="['/services']" (click)="navbarCollapsed = true">சேவைகள்</a>
      </li>

      <li class="nav-item" id="menu-item-41">
        <a class="nav-link" [routerLink]="['/contactus']" (click)="navbarCollapsed = true">தொடர்பு</a>
      </li>

      <!-- <li class="nav-item" id="menu-item-130">
        <a class="nav-link" [routerLink]="['/myaccount']" (click)="navbarCollapsed = true">என் கணக்கு</a>
      </li> -->


    </ul>

    <form class="form-inline my-2 my-lg-0">

      <div *ngIf="isLoggedIn=='true'" class="form-group row">
        <a class="nav-link" [routerLink]="['/myaccountedit']" (click)="navbarCollapsed = true">{{user_name}}</a>
        <button type="button" class="btn btn-primary" (click)="userLogout()">Logout</button>
      </div>

      <div *ngIf="isLoggedIn=='true'" class="form-group row">
        <input class="form-control mr-sm-2" type="search" placeholder="Search">
        <button class="btn btn-primary my-2 my-sm-0" type="submit">Search</button>
      </div>

    </form>
  </div>
</nav>

